import * as React from "react";
import Collage from "../components/Collage";
import Navbar from "../components/Navbar";
import { Helmet } from "react-helmet";
import Layout from "../components/Layout";

const IndexPage = () => {
  return (
    <main className="">
      <Layout>
        <Collage />
      </Layout>
    </main>
  );
};

export default IndexPage;
